import React from "react";

function Lightdark() {
  return (
    <div>
      <div>
        <input type="checkbox" class="checkbox" id="checkbox" />
        <label for="checkbox" class="checkbox-label">
          <i class="ri-moon-fill"></i>
          <i class="ri-sun-fill"></i>
          <span class="ball"></span>
        </label>
      </div>
    </div>
  );
}

export default Lightdark;
