import "./App.css";
import React, { useEffect, useState } from "react";

import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Uploadtype from "./Component/Uploadtype";
import Create from "./Component/Create";
import Login from "./Component/Login";
import Verifyaccount from "./Component/Verifyaccount";
import Mobilepage from "./Component/mobilepage";
import Register from "./Component/Register";
import Otp from "./Component/Otp";
import Myfund from "./Component/Myfund";
import Profile from "./Component/Profile";
import VerifyTfa from "./Component/verifyTfa";
import Activity from "./Component/Activity";
import Creators from "./Component/Creators";
import Staking from "./Component/Staking";
import Investmentplans from "./Component/Investmentplans";
import Launchpad from "./Component/Launchpad";
import Launchpadnow from "./Component/Launchpadnow";
import Launchpadbuy from "./Component/Launchpadbuy";
import Launchpadapplay from "./Component/Launchpadapplay";
import Support from "./Component/Support";
import Bankdetails from "./Component/sidebarbankdetails";
import P2Ppost from "./Component/P2Ppost";
import P2home from "./Component/P2home";
import P2Pchat from "./Component/P2Pchat";
import HomeNew from "./Component/HomeNew";
import Sidebarcontent from "./Component/Sidebarcontent";
import TransactionNew from "./Component/Sidebartransaction";
// import TradeNew from "./Component/SidebarTrade";
import SidebarLaunchpad from "./Component/SidebarLaunchpad";
import SidebarLaunchpadapplay from "./Component/SidebarLaunchpadApplay";
import SidebarLaunchpadbuy from "./Component/SidebarLaunchpadbuy";
import SidebarLaunchpadnow from "./Component/SidebarLaunchpadnow";
import SidebarP2ppost from "./Component/SidebarP2ppost";
import SidebarP2ppostnew from "./Component/SidebarP2ppostnew";
import SidebarP2pChat from "./Component/SidebarP2pChat";
import Stakingnew from "./Component/Sidebarstaking";
import InvestmentPlans from "./Component/sidebarInvestplan";
import Sidebarprofile from "./Component/Sidebarprofile";
import SidebarKyc from "./Component/SidebarKyc";
import SidebarSupport from "./Component/SidebarSupport";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "./Component/Chart";
import ForgotPassword from "./Component/ForgotPassword";
import Transfer from "./Component/Transfer";
import Sidebarp2pview from "./Component/Sidebarp2pview";
import Privacy from "./Component/Privacy";
import IdleTimeOutHandler from "./Component/IdleTimeOutHandler";
import P2PmyOrders from "./Component/P2PmyOrders";
import Sidebarp2pmyOrder from "./Component/Sidebarp2pmyOrder";
import SidebarP2Pedit from "./Component/SidebarP2Pedit";
import Sidebarp2pprocessOrder from "./Component/Sidebarp2pprocessOrder";
import Sidebarp2pconfirmOrder from "./Component/Sidebarp2pconfirmOrder";
import SidebarRefferal from "./Component/SidebarRefferal";
import Contactus from "./Component/Contactus";
import SidebarSwap from "./Component/SidebarSwap";
import RegisterNew from "./Component/RegisterNew";

// newpages
import LaunchpadList from "./Component/Pages/Launchpad/LaunchpadList";
import LaunchpadApplyNew from "./Component/Pages/Launchpad/LaunchpadApply";
import Launchpaddetails from "./Component/Pages/Launchpad/LaunchpadDetaisl";
import P2phomeNew from "./Component/Pages/P2p/P2phome";
import Userprofile from "./Component/Pages/Profile/Userprofile";
import Kycverify from "./Component/Pages/Profile/Kycverify";
import Security from "./Component/Pages/Profile/Security";
import Refferal from "./Component/Pages/Profile/Refferal";
import Supportnew from "./Component/Pages/Profile/Support";
import Historynew from "./Component/Pages/Profile/History";
import Sessionhistory from "./Component/Pages/Profile/Sessionhistory";
import Stakehome from "./Component/Pages/Stake/Stakehome";
import Stakedetails from "./Component/Pages/Stake/Stakedetails";
import Stakeconfirm from "./Component/Pages/Stake/Stakeconfirm";
import Swaphome from "./Component/Pages/Swap/Swaphome";
import Swapdetails from "./Component/Pages/Swap/Swapdetails";
import Swapconfirm from "./Component/Pages/Swap/Swapconfirm";
import Stakebackup from "./Component/Pages/Stake/stakebackup";
import P2ppostad from "./Component/Pages/P2p/P2ppostad";
import P2pinnerchat from "./Component/Pages/P2p/P2pinnerchat";
import P2pinnerchat2 from "./Component/Pages/P2p/P2pinnerchat2";
import P2pconfirm from "./Component/Pages/P2p/P2pconfirm";
import TradeNew from "./Component/Pages/Trade/Tradehome";
import Resetpass from "./Component/Resetpass";
import Withdraw from "./Component/Withdraw";
import Deposit from "./Component/Deposit";
import Tfastatus from "./Component/Pages/Profile/Tfastatus";
// import Setpass from "./Component/Setpass";
import Dashboard from "./Component/Pages/Dashboard/Dashboard";
import Landingpage from "./Component/Pages/Dashboard/Landingpage";
import Launchpadbuynew from "./Component/Pages/Launchpad/Launchpadbuynew";

import Paymentmethod from "./Component/Pages/Profile/Paymentmethod";

import P2pvieworder from "./Component/Pages/P2p/P2pvieworder";
import { removeAuthToken } from "./core/lib/localStorage";

import Swaphomenew from "./Component/Pages/Swap/Swaphomenew";

function App() {
  const [isActive, setIsActive] = useState(true);

  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  return (
    <>
      <Router>
        <IdleTimeOutHandler
          onActive={() => {
            setIsActive(true);
          }}
          onIdle={() => {
            setIsActive(false);
          }}
        />
        <ToastContainer />
        <Routes>
          {/*======================New Taikonz Router=====================*/}

          {/*======================Before Login =====================*/}
          <Route path="/" element={<Landingpage />} />
          <Route path="/Resetpass" element={<Resetpass />} />
          <Route path="/p2p" element={<P2phomeNew />} />
          <Route path="/p2p/post-ad" element={<P2ppostad />} />
          <Route path="/p2p/confirm-order/:id" element={<P2pinnerchat />} />{/* p */}
          <Route path="/p2p/chat/:id" element={<P2pinnerchat2 />} />
          <Route path="/p2p/complete/:id" element={<P2pconfirm />} />
          <Route path="/p2p/view-order/:id" element={<P2pvieworder />} />
          <Route path="/launchpadlistnew" element={<LaunchpadList />} />  
          <Route path="/LaunchpadApply" element={<LaunchpadApplyNew />} />
          <Route path="/Launchpadbuynew/:id" element={ <Launchpadbuynew />} />
          <Route path="/Tfalogin" element={ <Tfastatus />} />
          <Route path="/Stakehome" element={<Stakehome />} /> {/* p */}
          <Route path="/trade/:pair" element={<TradeNew />} />
          <Route path="/register" element={<RegisterNew />} />
          <Route path="/login" element={<Login />} />
          <Route path="/mobile" element={<Mobilepage />} />
          <Route path="/privacy" element={<Privacy />} />
          {/*=======================================================*/}

          {/*======================After Login =====================*/}
          <Route path="/Userprofile" element={ <RequireAuth> <Userprofile /></RequireAuth>} />
          <Route path="/Kycverify" element={ <RequireAuth> <Kycverify /></RequireAuth>} />
          <Route path="/Security" element={ <RequireAuth> <Security /></RequireAuth>} />
          <Route path="/Refferal" element={ <RequireAuth> <Refferal /></RequireAuth>} />
          <Route path="/Supportnew" element={ <RequireAuth> <Supportnew /></RequireAuth>} />
          <Route path="/Historynew" element={ <RequireAuth> <Historynew /></RequireAuth>} />
          <Route path="/Sessionhistory" element={ <RequireAuth> <Sessionhistory /></RequireAuth>} />
          <Route path="/Paymentmethod" element={ <RequireAuth> <Paymentmethod /></RequireAuth>} />
          <Route path="/Stakedetails" element={ <RequireAuth> <Stakedetails /></RequireAuth>} />
          <Route path="/Stakeconfirm" element={ <RequireAuth> <Stakeconfirm /></RequireAuth>} />
          <Route path="/Swaphome" element={ <RequireAuth> <Swaphome /></RequireAuth>} />
          <Route path="/Swaphomenew" element={ <RequireAuth> <Swaphomenew /></RequireAuth>} />
          <Route path="/Swapdetails" element={ <RequireAuth> <Swapdetails /></RequireAuth>} />
          <Route path="/Swapconfirm" element={ <RequireAuth> <Swapconfirm /></RequireAuth>} />
          <Route path="/Stakebackup" element={ <RequireAuth> <Stakebackup /></RequireAuth>} />
          <Route path="/Withdraw" element={ <RequireAuth> <Withdraw /></RequireAuth>} />
          <Route path="/deposit" element={ <RequireAuth> <Deposit /></RequireAuth>} />
          <Route path="/Dashboardpage" element={ <RequireAuth>  <Dashboard /></RequireAuth>} />
          <Route path="/tradeview/:pair" element={<Chart />} />
          {/*=======================================================*/}
        </Routes>
      </Router>
    </>
  );
}

export default App;
